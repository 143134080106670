export const accordionData = [
    {
      title: 'Are there any discounts?',
      content: `Yes, all discounts are posted within our discord`
    },
    {
      title: 'What is the format for the Master Gmail?',
      content: `The format is email:password:securityanswer:recoveryemail`
    },
    {
      title: 'Do we get access to all of the gmails on Elite Accounts?',
      content: `No, in order to maintain the quality of the product and reduce the chances of them getting clipped, you will only receive the master gmail that all gmails in the pack are forwarded to.`
    },
    {
        title: 'Is there a warranty?',
        p1: 'Standard',
        p2: 'All orders come with a ONE TIME USE clipping (where a code is requested) /reverification warranty. If either one of the cases occur to any of your accounts, we will offer a replacement.',
        p3: 'Elite',
        p4: 'Due to the fact that these are accounts made with real gmails that are all forwarded to your master gmail, the most we can do for an account that requires reverification or gets clipped, is attempt to save the account or offer you a replacement with another account that has email access.'
    },
    {
        title: 'Is there a minimum quantity required to purchase?',
        p1: 'Only our elite accounts have a minimum requirement. ',
        p2: 'They come in either packs of 10, 25 or 50 accounts. Though you must select either 11, 26 or 51 when making your purchase as that includes the Master Gmail.'
    }
  ];