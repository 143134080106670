import React, {useState, useEffect} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import arrow from '../../images/arrow.svg'
import underline from '../../images/underline.svg'
import './styles.scss'
import { accordionData } from './content';
import Accordion from './Accordian';

export default function FAQ() {
    useEffect(() => {
        AOS.init();
      }, []);

  return <div className='faq-wrapper' id="faq">
      <div className='faq-container'>
        <div className='title'>
          <h2>Frequently Asked Questions</h2>
          <img src={underline} alt='' />
        </div>
        <div className="accordion">
        {accordionData.map(({ title, content ,p1,p2,p3,p4}) => (
          <Accordion title={title} content={content} p1={p1} p2={p2} p3={p3} p4={p4}/>
        ))}
      </div> 
      </div>
  </div>;
}