import React, {useEffect} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { HashLink } from 'react-router-hash-link';
import './styles.scss'
import { Link } from 'react-router-dom';
import home from '../../images/home.svg'

export default function Home() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return  <div className='home-wrapper' id="home" data-aos="zoom-in">
      <div className='home-container'>
          <div className='left'>
            <h1>Built on the <br />Foundation of Quality <br />and <span>Transparency</span>.</h1>
            <div className='button'><HashLink smooth to="/#products"><button className='sheen'>Purchase</button></HashLink></div>
          </div>
          <div className='right'>
            <img src={home} alt="" />
          </div>
            
            
           

         
      </div> 
  </div>;
}
