import React, {useEffect, useState} from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.svg'
import burger from '../../images/burger.svg'
import './MobileNavbar.scss';

function MobileNavbar() {
    const [isVisible, setIsVisible] = useState();
    const [nav, setNav] = useState(false);

    const toggle = () => setIsVisible(!isVisible);

    const changeBackground = () => {
        if (window.scrollY >= 80) {
          setNav(true);
        } else {
          setNav(false);
        }
      };
      
    const largeScreens = () => {
        if (window.innerWidth > 1161) {
            setIsVisible(false);
        } 
      };
      useEffect(() => {
        window.addEventListener('resize', largeScreens);
      }, []);

      useEffect(() => {
        window.addEventListener('scroll', changeBackground);
        return () => {
          window.removeEventListener('scroll', changeBackground);
        };
      }, []);

    return (
        <div data-aos="fade-up" className={isVisible ? 'mobileNavbar-wrapper active': nav ? 'mobileNavbar-wrapper nav': 'mobileNavbar-wrapper'}>
            <div className='mobileNavbar-container'>
                <div className='top'>
                <Link to="/"><div><img src={logo} alt="" /></div></Link>    
                    <img src={burger} alt="" onClick={toggle} /> 
                </div>
                
                {isVisible && (
                <div  data-aos="fade-down" className='bottom' onClick={toggle}>
                      <HashLink smooth to="/#home">Home</HashLink>
                      <HashLink smooth to="/#features">Features</HashLink>
                      <HashLink smooth to="/#partnerships">Partnerships</HashLink>
                      <HashLink smooth to="/#products">Products</HashLink>
                      <HashLink smooth to="/#faq">FAQ</HashLink>
                      <a  href="https://discord.com/invite/vexaccounts">Discord</a>
                      <a  href="https://dashboard.vexsolutions.co/">Dashboard</a>
                </div>
                )}
            </div>
        </div>
    )
}

export default MobileNavbar
