import React from 'react'
import './styles.scss'

export default function RE() {
  return (
    <div className='tos'>
      <div>
        <h4>Refunds &amp; Exchanges</h4>
        <p>
        <span style={{fontWeight: 'bold'}}>ALL SALES ARE FINAL.</span> We do not offer any refunds whatsoever, and all sales are considered final upon purchase. We are not responsible for providing refunds due to any actions taken by you that may cause the product to no longer function or for any changes made outside of our control that may render the product inoperable. Our guarantee is limited to ensuring that the product functions properly upon delivery.
        </p>

        <p>If you forget to apply a discount during your purchase, we are not responsible for this oversight and will not offer any partial refunds. Refunds will ONLY be considered if we determine the product to be defective upon being contacted by you.</p>
        <p>For assistance with your order, please open a ticket in our Discord server provided below. We will then take the necessary steps to address your concerns and assist you with your order:</p>
        <a href='https://discord.gg/vexaccounts'>https://discord.gg/vexaccounts</a>
        </div>
    </div>
  )
}
