import React, {useEffect} from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import p1 from '../../images/p1.svg'
import p2 from '../../images/p2.svg'
import './styles.scss'
import underline from '../../images/underline.svg'

export default function Partnerships() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className='partnerships-wrapper' id='partnerships'>
      <div className='partnerships-container'>
        <div className='title'>
          <h2>Partnerships</h2>
          <img src={underline} alt='' />
        </div>
       
        <div className='content'>
         <img src={p1} alt="" data-aos="fade-right"/>
         <img src={p2} alt="" data-aos="fade-left"/>
        </div>
       
      </div>
    </div>
  )
}
