import React, {useEffect} from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import i1 from '../../images/i1.svg'
import i2 from '../../images/i2.svg'
import i3 from '../../images/i3.svg'
import './styles.scss'
import FeaturesCard from './FeaturesCard/FeaturesCard';
import underline from '../../images/underline.svg'

export default function Features() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className='features-wrapper' id='features'>
      <div className='features-container'>
        <div className='title'>
          <h2>Our Features</h2>
          <img src={underline} alt='' />
        </div>
       
        <div className='content cards'>
           <div className='featuresCard-wrapper card card-one' data-aos="fade-zoom-in">
            <img src={i1} alt="" />
            <h5>Top-Tier Accounts</h5>
            <p>All of our accounts are made with our custom in-house gen that is made to emulate the process of handmade accounts, therefore giving them the same quality that handmade accounts would provide, at a much more affordable price.</p>
          </div>

          <div className='featuresCard-wrapper card card-two' data-aos="fade-zoom-in">
            <img src={i2} alt="" />
            <h5>Instant Delivery</h5>
            <p>We have an automated and instantaneous delivery system in place for all orders, allowing them to be delivered and automatically uploaded to your dashboard.  All orders can easily be accessed and managed, making the process of renewing simple. </p>
          </div>

          <div className='featuresCard-wrapper card card-three' data-aos="fade-zoom-in">
            <img src={i3} alt="" />
            <h5>Unmatched Speed</h5>
            <p>Our commitment to delivering lightning-fast data center speeds is upheld by our strategic partnership with Tier-1 networks and the use of state-of-the-art hardware. For optimum performance, all our proxies are hosted on 10GBPS servers in Ashburn, VA.</p>
          </div>
        </div>
       
      </div>
    </div>
  )
}
