import React, { useState } from 'react'
import PlanCard from './PlanCard/PlanCard'
import underline from '../../images/underline.svg'
import './styles.scss'
import ToggleSwitch from '../../components/Toggle/ToggleSwitch';
import bullet from '../../images/bullet.svg'

export default function Plans() {
  const [clicked, setClicked] = useState(false);
  const handleChange = () => {
    setClicked(!clicked);
  };

  return (
    <div className='plans-wrapper' id="products">
      <div className='plans-container'>
      <div className='title'>
          <h2>Products</h2>
          <img src={underline} alt='' />
        </div>

        <div className='tabs'>
          <h4 style={{marginRight:20}}>Proxies</h4>
          <div className=''>
          <ToggleSwitch label="proxies" clicked={clicked} handleChange={handleChange}/>
        </div>
          <h4 style={{marginLeft:20}}>Accounts</h4>
        </div>
      
        {( clicked === false) && <div className='content'>
          {[
            {
              title: "Tier 1",
              price: "2.20",
              type: "Proxy",

              l1: "Working on Nike/SNKRS and Retail Sites Upon Delivery",
              l2: "Tailored exclusively for Nike/SNKRS",
              l3: "Hosted on 10GBs Ashburn Servers",
              l4: "Instant Delivery",
              l5: "user:password Authentication",
              link: "https://dashboard.vexautomations.co/datacenter"
            
             
            },
          ].map((item, i)=> (
            <PlanCard key={i} data={item}/>
          ))}
          <div className='planCard-wrapper' data-aos="zoom-right">
                  <div className='title'>
                  <h3>Tier 2</h3>
                  </div>
                  
                
                      <div className='middle' style={{marginTop: 65}}>
                          <ul>
                              <li><img src={bullet} alt="" /> COMING SOON</li>
                             
                          </ul>
                          
                      </div>
                      
            
              </div>

        </div>}

        {(clicked === true ) && <div className='content'>
          {[
            {
              title: "Standard",
              price: "2.50",
              type: "Account",

              l1: "Instant Delivery",
              l2: "SMS Verified",
              l3: "Created on a High Quality IP",
              l4: "Works on all Nike/SNKRS Regions",
              l5: "email:password",
              link: "https://dashboard.vexautomations.co/accounts"
             
            },
            {
              title: "Standard+",
              price: "4.00",
              type: "Account",

              l1: "Instant Delivery",
              l2: "US SMS Verified ",
              l3: "Includes a High Quality Proxy",
              l4: "Works on all Nike/SNKRS Regions",
              l5: "email:password",
              link: "https://dashboard.vexautomations.co/accounts"
             
            },
            {
              title: "Elite",
              price: "4.75",
              type: "Account",

              l1: "Instant Delivery",
              l2: "Email Forwarding to Master Gmail",
              l3: "USA SMS Verified ",
              l4: "Created on a High Quality IP",
              l5: "10, 25 or 50 Nike Accounts Per Pack (+1 Master Gmail)",
              link: "https://dashboard.vexautomations.co/accounts"
             
            },
          ].map((item, i)=> (
            <PlanCard key={i} data={item}/>
          ))}
        </div>}
      </div>
    </div>
  )
}
