import React, {useEffect, useState} from 'react';
import './Navbar.scss';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.svg'

function Navbar() {
    const [nav, setNav] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 100) {
          setNav(true);
        } else {
          setNav(false);
        }
      };

      useEffect(() => {
        window.addEventListener('scroll', changeBackground);
        return () => {
          window.removeEventListener('scroll', changeBackground);
        };
      }, []);

    return (
        <div data-aos="fade-up" data-aos-anchor-placement="top-bottom"className={nav ? 'navbar-wrapper active' : 'navbar-wrapper'}>
            <div className='navbar-container'>
              <Link to="/"><div><img src={logo} alt='' /></div></Link>   
              <div>
                <div data-aos="zoom-in" className='links'>
                      <HashLink smooth to="/#home">Home</HashLink>
                      <HashLink smooth to="/#features">Features</HashLink>
                      <HashLink smooth to="/#partnerships">Partnerships</HashLink>
                      <HashLink smooth to="/#products">Products</HashLink>
                      <HashLink smooth to="/#faq">FAQ</HashLink>
                      <a  href="https://discord.com/invite/vexaccounts">Discord</a>
                  </div>

                  

                  <div className='button'>

                  <a href="https://dashboard.vexsolutions.co/"><button className='sheen'>Dashboard</button></a>
                  </div>
              </div>
                
            </div>
        </div>
    )
}

export default Navbar
