import React from 'react';
import Home from '../Home/Home'
import Features from '../Features/Features'
import Footer from '../../components/Footer/Footer'
import Plans from '../Plans/Plans';
import Partnerships from '../Partnerships/Partnerships';
import FAQ from '../FAQ/FAQ';

export default function Main() {
  return <div>
      <Home />
      <Features />
      <Partnerships />
      <Plans />
      <FAQ />
      <Footer /> 
  </div>;
}
