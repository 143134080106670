import React, { useState } from 'react';
import arrow from '../../images/arrow.svg'

const Accordion = ({ title, content,p1,p2,p3,p4 }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item faqs faqcard-wrapper">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <h3 style={{fontWeight: 'bold', margin: 0, fontSize: 16, marginTop: 10}}>{title}</h3>
        <div className='selector-container'><img src={arrow} alt='' className={isActive ? "clicked" : ""}/></div>
      </div>
      {isActive && <div className="accordion-content">
        <p>{content}</p>
        <p>{p1}</p>
        <p>{p2}</p>
        <p>{p3}</p>
        <p>{p4}</p>
        </div>}
    </div>
  );
};

export default Accordion;