import React from 'react'
import './styles.scss'
import { Link } from 'react-router-dom'
import logo from '../../images/logo.svg'
import twitter from '../../images/twitter.svg'
import discord from '../../images/discord.svg'
export default function Footer() {
  return (
    <div className='footer-wrapper'>
      <div className='footer-content'>

        <div>
        <img src={logo} alt="" style={{width: 70}}/>
        
       </div>

      <div>
        <h1>Important Links</h1>
        <div>
          <Link style={{marginRight:20}} to="/termsofservices">Terms of Service</Link>
          <Link to="/refunds&exchages">Refunds & Exchanges</Link>
        </div>
       
       </div>

       <div>
        <h1>Socials</h1>
        <div>
          <a href="https://twitter.com/VexSolutions_"><img src={twitter} alt="" /></a>
          <a href="https://discord.gg/vexaccounts"><img src={discord} alt="" style={{width: 20, marginLeft: 10}}/></a>
        </div>
       </div>

    
      </div>
      <p style={{color: "#fff", marginBottom:0}}>© Copyright Vex Solutions. All Rights Reserved</p>
    </div>
  )
}
