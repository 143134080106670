import React, {useEffect, useState} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './styles.scss'
import bullet from '../../../images/bullet.svg'

export default function PlanCard(props) {
    const {data} = props;
    const [selectValue, setSelectValue] = useState(data.p1)
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
 
  return (
    <div className='planCard-wrapper' data-aos="zoom-right">
        <div className='title'>
        <h3>{data.title}</h3>
        </div>
         

          { data.title === 'Elite' && <p style={{marginBottom: 0, color: '#fff', fontSize: 12}}>Starting At</p>}
            <div style={{display: 'flex', alignItems: 'flex-end', marginBottom:15, marginTop:0}}>
                <span style={{fontSize: 18, color:"#fff", marginBottom: 3, marginRight: 3}}>$</span>
                <h1>{data.price}</h1>
                <h6>Per {data.type}</h6>
            </div>
       
            <div className='middle'>
                <ul>
                    <li><img src={bullet} alt="" /> {data.l1}</li>
                    <li><img src={bullet} alt="" /> {data.l2}</li>
                    <li><img src={bullet} alt="" /> {data.l3}</li>
                    <li><img src={bullet} alt="" /> {data.l4}</li>
                    <li><img src={bullet} alt="" /> {data.l5}</li>
                    {data.l6 && <li><img src={bullet} alt="" /> {data.l6}</li>}
                    {data.i7 && <li><img src={bullet} alt="" /> {data.l7}</li>}
                    {data.i8 && <li><img src={bullet} alt="" /> {data.l8}</li>}
                </ul>
                
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}} className='button'><a href={data.link}><button className='sheen'>Purchase</button></a></div>
  
    </div>
  )
}
