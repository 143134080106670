import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import MobileNavbar from './components/MobileNavbar/MobileNavbar'
import Main from "./pages/Main/Main";
import TOS from './pages/TOS/TOS'
import RE from "./pages/RE/RE";

function App() {
  return (
    <div className="App">
     <Navbar />
      <MobileNavbar />
      <Routes>
          <Route exact path="/termsofservices" element={<TOS />}/>
          <Route exact path ="/refunds&exchages" element={<RE />} />
        <Route path="/" element={<Main />} />
      </Routes>
    </div>
  );
}
export default App;
